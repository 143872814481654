import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactWordcloud from 'react-wordcloud';

export default function Skills() {
  const words = [
    {text: 'Web Development', value: 100},
    {text: 'ERP Development', value: 100},
    {text: 'Mobile App Development', value: 100},
    {text: 'Scrum', value: 100},
    {text: 'OOP', value: 100},
    {text: 'Agile', value: 90},
    {text: 'JavaScript', value: 90},
    {text: 'x++', value: 90},
    {text: 'HTML', value: 90},
    {text: 'PHP', value: 90},
    {text: '.NET', value: 90},
    {text: 'SQL', value: 90},
    {text: 'XML', value: 80},
    {text: 'CSS', value: 80},
    {text: 'JSON', value: 80},
    {text: 'XSLT', value: 80},
    {text: 'ASP', value: 80},
    {text: 'Powershell', value: 80},
    {text: 'jQuery', value: 70},
    {text: 'React', value: 70},
    {text: 'Bootstrap', value: 70},
    {text: 'MySQL', value: 70},
    {text: 'MongoDB', value: 70},
    {text: 'CakePHP', value: 60},
    {text: 'Wordpress', value: 60},
    {text: 'Drupal', value: 60},
    {text: 'Node.js', value: 60},
    {text: 'Express', value: 60},
    {text: 'Azure DevOps', value: 60},
    {text: 'Microsoft Office', value: 60},
    {text: 'Microsoft Dynamics 365', value: 60},
    {text: 'Logic Apps', value: 60},
    {text: 'Power Automate', value: 60},
    {text: 'Power Apps', value: 60},
  ];

  const options = {
    fontFamily: 'Open Sans', 
    fontSizes: [15, 60], 
    colors: ['#ce0000', '#801717', '#bd3939', '#d17d7d'],
    rotations: 2, 
    rotationAngles: [0, 90], 
    deterministic: true, 
    randomSeed: 'seed2', 
    enableTooltip: false}

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col sm={12}>
            <ReactWordcloud words={words} options={options} />
          </Col>
        </Row>
      </Container>
    </>
  );
}